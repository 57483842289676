<template>
  <div id="parent">
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <b-alert
        :show="!!showMt4Card"
        variant="warning"
      >
        <h4 class="alert-heading d-sm-flex justify-content-sm-between align-items-sm-center">
          <div class="mb-1">
            {{ $t(`transfer_cabin_card.${showMt4Card}.alert_title`) }}
          </div>
          <b-button
            variant="warning"
            class="btn-tour-skip mr-1 w-100 w-sm-auto"
            @click="createMt4AccountModal = true"
          >{{ $t(`transfer_cabin_card.${showMt4Card}.alert_btn`) }}</b-button>
        </h4>
      </b-alert>
      <b-card v-if="createCard">
        <!-- <b-row
          align-v="center"
          class="mb-2 pb-1"
          style="border-bottom: 1px solid #ddd"
        >
          <b-col
            class="text-center"
            style="cursor: pointer"
            cols="auto"
            @click="$router.go(-1)"
          >
            <b-col cols="12">
              {{ $t("back") }}
            </b-col>
            <b-col cols="12">
              <feather-icon
                v-if="$i18n.isRTL"
                icon="ArrowRightIcon"
                size="14"
              />
              <feather-icon
                v-else
                icon="ArrowLeftIcon"
                size="14"
              />
            </b-col>
          </b-col>

        </b-row> -->
        <b-row>
          <b-col
            md="6"
            class="card-border"
          >
            <b-card-body>
              <b-alert
                v-show="groupSelected.max_deposit || groupSelected.min_deposit"
                id="max-min-deposit-alert"
                show
                variant="warning"
                class="p-1"
              >
                <div class="alert-body">
                  <p class="text-justify">
                    {{ $t('account-creation-msg', { group: groupSelected.name , min:numberWithCommas(groupSelected.min_deposit || 0) , max:numberWithCommas(groupSelected.max_deposit || 0) }) }}
                  </p>
                  <p
                    v-if="groupSelected.min_deposit"
                    class="text-justify"
                  >
                    {{ $t('min-deposit-msg', { min:numberWithCommas(groupSelected.min_deposit || 0) }) }}
                  </p>
                  <p
                    v-if="groupSelected.max_deposit"
                    class="text-justify"
                  >
                    {{ $t('max-deposit-msg', {max:numberWithCommas(groupSelected.max_deposit || 0) }) }}
                  </p>
                </div>
              </b-alert>
              <validation-observer
                v-slot="{invalid}"
                ref="createAccount"
              >
                <b-form class="mt-2">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group
                        :label="$t('forex.platform')"
                        class="mb-1"
                        label-for="platform"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('forex.platform')"
                          vid="platform"
                          rules="required"
                        >
                          <v-select
                            id="platform"
                            v-model="platformSelected"
                            :options="platforms"
                            label="name"
                            :clearable="false"
                            @input="onchangePlatform"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <label for="">{{ $t('groupTitle') }}

                        <feather-icon
                          v-b-modal.modal-center
                          icon="HelpCircleIcon"
                          class="cursor-pointer"
                          size="15"
                        />

                        <b-modal
                          id="modal-center"
                          centered
                          hide-footer
                        >
                          <p class="my-1">test</p>
                        </b-modal>
                      </label>
                      <b-form-group
                        class="mb-1"
                        label-for="group"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('forex.group')"
                          vid="group"
                          rules="required"
                        >
                          <v-select
                            id="group"
                            v-model="groupSelected"
                            :options="group"
                            label="name"
                            @input="onchangeGroup"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group
                        label-for="leverage"
                        :label="$t('forex.leverage')"
                        class="mb-1"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          :name="$t('forex.leverage')"
                          vid="leverage"
                          rules="required"
                        >
                          <v-select
                            id="leverage"
                            v-model="leverageSelected"
                            label="name"
                            :options="leverage"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group></b-col>
                    <b-col
                      cols="12"
                      class="d-flex justify-content-center"
                    >
                      <b-button
                        id="button"
                        variant="primary"
                        class="mt-1"
                        :disabled="invalid"
                        @click="createAcc"
                      >
                        <span v-if="!pending">
                          {{ $t('forex.create-account') }}</span>
                        <b-spinner
                          v-if="pending"
                          variant="light"
                          type="grow"
                          small
                        />
                      </b-button>
                    </b-col>
                    <p
                      v-if="$store.state.profile.profileInfo.document&&$store.state.profile.profileInfo.document.general && $store.state.profile.profileInfo.document.general.status !=='success'"
                      class="mt-2 text-warning"
                    >
                      {{ $t('main_password') }}
                    </p>

                  </b-row>
                </b-form>
              </validation-observer>
            </b-card-body>
          </b-col>
          <b-col
            md="6"
          >
            <b-card-body class="d-flex d-sm-block flex-column flex-column-reverse">
              <div class="text-md-left text-justify">

                <p>
                  {{ $t('forex.create-account-desc') }}
                </p>
                <p>- {{ $t('forex.features-platform-one') }}
                </p>
                <p>- {{ $t('forex.features-platform-two') }}
                </p>
                <p>- {{ $t('forex.features-platform-three') }}
                </p>
                <p>- {{ $t('forex.features-platform-four') }}
                </p>
                <p>- {{ $t('forex.features-platform-five') }}
                </p>
              </div>
              <div
                class="text-center my-3  d-sm-block"
                style="justify-content:space-around"
              >
                <p class="d-sm-none">
                  {{ $t('metatrader-downlod-text') }}
                </p>
                <b-dropdown
                  v-if="metatraderVersions.includes('mt4')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  class="h-100 mb-1 mx-1 w-300"
                  variant="outline-dark"
                >
                  <template #button-content>
                    <b-img
                      fluid
                      width="24"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/meta-trader4.png')"
                    />
                    <span class="align-middle">{{ $t('metatrader4-download') }}</span>
                  </template>
                  <b-dropdown-item
                    href="https://download.mql5.com/cdn/web/pcm.international.limited/mt4/pcmtrader4setup.exe"
                    target="_blank"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/windows.png')"
                    />
                    {{ $t('forex.metaTrader4-windows-download') }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    target="_blank"
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.pkg.zip"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/mac.png')"
                    />
                    {{ $t('forex.metaTrader4-mac-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://apps.apple.com/us/app/metatrader-4/id496212596"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/apple.png')"
                    />
                    {{ $t('forex.metaTrader4-ios-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4"
                  >
                    <b-img
                      fluid
                      width="18"
                      :src="require('@/assets/images/forex/android.png')"
                    />
                    {{ $t('forex.metaTrader4-android-download') }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  v-if="metatraderVersions.includes('mt5')"
                  v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                  class="h-100 mb-1 mx-1  w-300  mx-auto"
                  variant="outline-dark"
                >
                  <template #button-content>
                    <b-img
                      fluid
                      width="24"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/meta-trader5.png')"
                    />
                    <span class="align-middle">{{ $t('metatrader5_download') }}</span>
                  </template>
                  <b-dropdown-item
                    target="_blank"
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe"
                  >
                    <b-img
                      fluid
                      width="18"
                      :src="require('@/assets/images/forex/windows.png')"
                    />
                    {{ $t('forex.metaTrader5-windows-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://www.mql5.com/en/articles/625"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/linux.png')"
                    />
                    {{ $t('forex.metaTrader5-linux-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/mac.png')"
                    />
                    {{ $t('forex.metaTrader5-mac-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://apps.apple.com/us/app/metatrader-5/id413251709"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/apple.png')"
                    />
                    {{ $t('forex.metaTrader5-ios-download') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/android.png')"
                    />
                    {{ $t('forex.metaTrader5-android-download') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-card-body>
          </b-col>

        </b-row>
      </b-card>

      <b-card
        v-if="cardInfo"
        id="successCard"
        class="mt-4 "
      >
        <b-alert
          show
          variant="success"
          class="p-1"
        >
          <p class="text-justify">
            {{ $t('forex.account-opening-msg') }}
          </p>
          <p class="my-2">
            {{ $t('metatrader-downlod-text') }}
          </p>
          <b-row
            class="d-sm-flex"
          >
            <b-col
              v-if="metatraderVersions.includes('mt4')"
              cols="12"
              sm="4"
              class="my-1"
            >
              <b-dropdown
                variant="primary"
                block
              >
                <template #button-content>
                  <b-img
                    width="22"
                    class="mx-1"
                    :src="require('@/assets/images/forex/meta-trader4.png')"
                  />
                  <span class="align-middle">{{ $t('metatrader4-download') }}</span>
                </template>
                <b-dropdown-item
                  href="https://download.mql5.com/cdn/web/pcm.international.limited/mt4/pcmtrader4setup.exe"
                  target="_blank"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/windows.png')"
                  />
                  {{ $t('forex.metaTrader4-windows-download') }}
                </b-dropdown-item>
                <!-- <b-dropdown-item
                    target="_blank"
                    href="https://www.mql5.com/en/articles/1358"
                  >
                    <b-img
                      fluid
                      width="18"
                      style="margin-right: 3px;"
                      :src="require('@/assets/images/forex/linux.png')"
                    />
                    {{ $t('forex.metaTrader4-linux-download') }}
                  </b-dropdown-item> -->
                <b-dropdown-item
                  target="_blank"
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.pkg.zip"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/mac.png')"
                  />
                  {{ $t('forex.metaTrader4-mac-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://apps.apple.com/us/app/metatrader-4/id496212596"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/apple.png')"
                  />
                  {{ $t('forex.metaTrader4-ios-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader4"
                >
                  <b-img
                    fluid
                    width="18"
                    :src="require('@/assets/images/forex/android.png')"
                  />
                  {{ $t('forex.metaTrader4-android-download') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col
              v-if="metatraderVersions.includes('mt5')"
              cols="12"
              sm="4"
              class="my-1"
            >
              <b-dropdown
                variant="primary"
                block
              >
                <template #button-content>
                  <b-img
                    fluid
                    width="22"
                    class="mx-1"
                    :src="require('@/assets/images/forex/meta-trader5.png')"
                  />
                  <span class="align-middle">{{ $t('metatrader5_download') }}</span>
                </template>
                <b-dropdown-item
                  target="_blank"
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/mt5setup.exe"
                >
                  <b-img
                    fluid
                    width="18"
                    :src="require('@/assets/images/forex/windows.png')"
                  />
                  {{ $t('forex.metaTrader5-windows-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://www.mql5.com/en/articles/625"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/linux.png')"
                  />
                  {{ $t('forex.metaTrader5-linux-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.pkg.zip"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/mac.png')"
                  />
                  {{ $t('forex.metaTrader5-mac-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://apps.apple.com/us/app/metatrader-5/id413251709"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/apple.png')"
                  />
                  {{ $t('forex.metaTrader5-ios-download') }}
                </b-dropdown-item>
                <b-dropdown-item
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=net.metaquotes.metatrader5"
                >
                  <b-img
                    fluid
                    width="18"
                    style="margin-right: 3px;"
                    :src="require('@/assets/images/forex/android.png')"
                  />
                  {{ $t('forex.metaTrader5-android-download') }}
                </b-dropdown-item>
              </b-dropdown>
            </b-col>
            <b-col
              cols="12"
              sm="4"
              class="my-1"
            >
              <b-button
                block
                style="line-height: normal;"
                variant="success"
                :to="{name:'forexModule-deposit',params:{id:accInfo.account_id} }"
              >
                {{ $t('deposit-pageTitle') }}
              </b-button>
            </b-col>
          </b-row>

        </b-alert>
        <b-form class="mt-3">
          <b-row>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('forex.account-number')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="account number"
                  vid="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.account_id"
                    :placeholder="$t('forex.account-number')"
                    name="city"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="group"
                :label="$t('forex.group')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="group"
                  vid="group"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.group"
                    :placeholder="$t('forex.group')"
                    name="group"
                    disabled
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('forex.leverage')"
                label-for="leverage"
              >
                <validation-provider
                  #default="{ errors }"
                  name="leverage"
                  vid="leverage"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.leverage"
                    :placeholder="$t('forex.leverage')"
                    name="leverage"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('forex.original-password')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  name="original password"
                  vid="city"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.password"
                    dir="ltr"
                    :placeholder="$t('forex.original-password')"
                    name="city"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                label-for="investor password"
                :label="$t('forex.investor-password')"
              >
                <validation-provider
                  #default="{ errors }"
                  name="investor password"
                  vid="investor password"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.password_investor"
                    dir="ltr"
                    :placeholder="$t('forex.investor-password')"
                    name="investor password"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              xl="6"
              class="col-xxl-4"
            >
              <b-form-group
                :label="$t('forex.phone-password')"
                label-for="phone password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="phone password"
                  vid="phone password"
                  rules="required"
                >
                  <b-form-input
                    v-model="accInfo.password_phone"
                    :placeholder="$t('forex.phone-password')"
                    name="phone password"
                    disabled
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-overlay>
    <b-modal
      v-model="createMt4AccountModal"
      centered
      :title="$t(`transfer_cabin_card.${showMt4Card}.modal_title`)"
      no-close-on-backdrop
    >
      <div v-html="$t(`transfer_cabin_card.${showMt4Card}.modal_description`)" />
      <template #modal-footer>
        <b-button
          variant="success"
          size="sm"
          block
          :disabled="createMt4AccountTimer.minutes > 0 || createMt4AccountTimer.seconds > 0 || createMt4AccountShow"
          @click="createMt4Account"
        >

          <span
            v-if="createMt4AccountTimer.minutes > 0 || createMt4AccountTimer.seconds > 0"
            class="mx-1"
          >
            {{ formatTime(createMt4AccountTimer.seconds) }}
            :
            {{ formatTime(createMt4AccountTimer.minutes) }}
          </span>
          <span v-else-if="createMt4AccountShow">
            <b-spinner
              small
              type="grow"
            /></span>
          <span v-else>{{ $t(`transfer_cabin_card.${showMt4Card}.modal_btn`) }}</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BDropdown,
  BDropdownItem,
  BImg,
  BAlert,
  BModal,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import MetatraderService from '@/modules/forex/service/apis'
import ProfileApis from '@/services/apis/profile'
import { numberWithCommas } from '@/utils'

const metaTraderAccount = new MetatraderService()
const profile = new ProfileApis()

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BSpinner,
    vSelect,
    BForm,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BImg,
    BAlert,
    BModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      numberWithCommas,
      pending: false,
      cardInfo: false,
      createCard: true,
      platformSelected: '',
      groupSelected: '',
      leverageSelected: '',
      leverage: [],
      group: [],
      required,
      show: false,
      platforms: [],
      accInfo: {
        account_id: '',
        group: '',
        leverage: '',
        password: '',
        password_investor: '',
        password_phone: '',
      },
      createMt4AccountModal: false,
      createMt4AccountTimer: {
        minutes: 0,
        seconds: 0,
      },
      createMt4AccountRemaind: null,
      createMt4AccountShow: false,
    }
  },
  computed: {
    metatraderVersions() {
      return process.env.VUE_APP_PLATFORMS.split(',')
    },
    showMt4Card() {
      return process.env.VUE_APP_TRANSFER_CABIN_CARD
    },
  },
  mounted() {
    this.getPlatform()
  },
  methods: {
    async getPlatform() {
      await metaTraderAccount.getPlatforms().then(res => {
        this.platforms = res.data.results
      })
    },
    onchangePlatform(item) {
      this.show = true
      metaTraderAccount.getAllGroups({ platform: item.value }).then(res => {
        const alert = document.getElementById('max-min-deposit-alert')
        const isMobile = window.matchMedia('(max-width: 767px)').matches
        if ((item.max_deposit || item.min_deposit) && alert && isMobile) {
          alert.scrollIntoView()
        }
        this.group = res.data.results
        this.groupSelected = ''
        this.leverageSelected = ''
        this.leverage = ''
      }).finally(() => {
        this.show = false
      })
    },
    onchangeGroup(group) {
      this.leverage = this.group.filter(item => item.name === group.name)[0].leverages
    },
    createAcc() {
      this.pending = true
      const data = { platform: this.platformSelected.value, group_id: this.groupSelected.id, leverage_id: this.leverageSelected.id }
      metaTraderAccount.createAccount(data).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 4000,
          showConfirmButton: false,
        })
        this.cardInfo = true
        this.createCard = false
        this.accInfo = res.data.results
        profile.get()
      }).finally(() => {
        this.pending = false
        this.platformSelected = ''
        this.groupSelected = ''
        this.leverageSelected = ''
        this.$refs.createAccount.reset()
        const elementOne = document.getElementById('parent')
        const elementTwo = document.getElementById('successCard')
        window.scroll({
          top: elementOne.scrollHeight - elementTwo.scrollHeight,
          behavior: 'smooth',
        })
      })
    },
    createMt4Account() {
      this.createMt4AccountShow = true
      profile.dataTransfer().then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false,
        })
        const newtab = window.open('about:blank')
        newtab.location.href = `${process.env.VUE_APP_TRANSFER_CABIN_PATH}`
        this.createMt4AccountRemaind = 60
        this.updateCountdown()
      }).finally(() => {
        this.createMt4AccountShow = false
      })
    },
    updateCountdown() {
      if (this.createMt4AccountRemaind > 0) {
        this.createMt4AccountTimer.minutes = Math.floor(this.createMt4AccountRemaind / 60)
        this.createMt4AccountTimer.seconds = this.createMt4AccountRemaind % 60
        this.createMt4AccountRemaind -= 1

        setTimeout(this.updateCountdown, 1000) // Call itself after 1 second
      } else {
        this.createMt4AccountTimer.minutes = 0
        this.createMt4AccountTimer.seconds = 0
      }
    },
    formatTime(value) {
      return value.toString().padStart(2, '0')
    },

  },
}
</script>
<style scoped>
.card-border {
  border-right: 1px solid #e3e1e9;
}
.w-300{
  width:300px !important;
}
</style>
